import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Modal,
  Snackbar,
} from "@material-ui/core";
import {
  setItemTaskType,
  setItemTaskName,
  getTaskData,
  getTaskDataFromSearchTerm,
  setPage,
  setRowsPerPage,
  setSortDirection,
  setSearchQuery,
  setSearchData,
  setToastError,
  setToastWarning,
  setOrderBy,
} from "../store/reducers/slices/searchSlice";
import { useSelector, useDispatch } from "react-redux"
import AddToQueue from "../components/config/AddToQueue";
import LoadingSpinner from "../components/global/LoadingSpinner";
import Toast from "../components/toast/Toast";
import {
  resetSearchQueueData,
  resetAlert,
} from "../store/reducers/slices/addTaskQueueSlice";
import { Alert } from "@material-ui/lab";
import TaskTypeSelectList from "../components/global/TaskTypeSelectList";
import PaperContainer from "../components/Containers/PaperContainer";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from '@material-ui/icons/Add';
import ReactTable from "../components/tables/ReactTable/ReactTable";
import SelectColumnFilter from "../components/tables/ReactTable/SelectColumnFilter";
import moment from "moment";
import SelectColumnFilterWithoutTimev2 from "../components/tables/ReactTable/SelectColumnFilterWithoutTimev2";

const SearchPageTableView = ({
  setIsSelectedAnalytics,
  setIsSelectedSettings,
  setIsSelectedSearch,
  setIsSelectedMonitoring,
  setIsSelectedBarcodeSearch,
}) => {

  const columnsForTable = [
    {
      accessor: "task_queue_id",
      id: "task_queue_id",
      Header: "Queue id",
      disableFilters: true,
    },
    {
      accessor: "Record",
      id: "Record",
      Header: "Record",
      Filter: SelectColumnFilter
    },
    {
      accessor: (originalRow) => moment(originalRow['task_date_requested'], 'DD-MM-YYYY HH:mm:ss').toDate(),
      id: "task_date_requested",
      Header: "Date requested",
      Filter: SelectColumnFilterWithoutTimev2,
      Cell: (params) => {
        return (
          <>
            {moment(params.row.values.task_date_requested, 'DD-MM-YYYY HH:mm:ss').format("DD/MM/YYYY, HH:mm:ss").toString()}
          </>
        )
      },
      sortType: 'datetime'
    },
    {
      accessor: (originalRow) => moment(originalRow['task_date_completed'], 'DD-MM-YYYY HH:mm:ss').toDate(),
      id: "task_date_completed",
      Header: "Date completed",
      Filter: SelectColumnFilterWithoutTimev2,
      Cell: (params) => (
        <>
          {params.row.values.task_date_completed ? moment(params.row.values.task_date_completed, 'DD-MM-YYYY HH:mm:ss').format("DD/MM/YYYY, HH:mm:ss").toString() : "processing..."}
        </>
      ),
      sortType: 'datetime'
    },
  ];

  const dispatch = useDispatch();
  const { taskTypesWithCounts } = useSelector(
    (state) => state.manageDashboardData
  );

  const {
    paginationTaskTotal,
    page,
    rowsPerPage,
    sortDirection,
    itemTaskType,
    searchData,
    dataLoading,
    toastWarning,
    toastError,
    orderBy,
  } = useSelector((state) => state.manageSearch);

  const [taskTypeSelectList, setTaskTypeSelectList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddTable, setShowAddTable] = useState(false);
  const [validateSearchField, setValidateSearchField] = useState(false);
  const { alertData } = useSelector((state) => state.manageQueueAddData);

  useEffect(() => {
    setIsSelectedAnalytics(false);
    setIsSelectedSettings(false);
    setIsSelectedSearch(true);
    setIsSelectedMonitoring(false);
    setIsSelectedBarcodeSearch(false);
    dispatch(setSearchData([]));
    dispatch(getTaskData({}));
    populateSelectList();
  }, []);

  useEffect(() => {
    if (taskTypesWithCounts?.length > 0) {
      setTaskTypeSelectList(taskTypesWithCounts);

      populateSelectList();
    }
  }, [taskTypesWithCounts]);

  const showAdd = () => {
    setShowAddTable(true);
  };
  const handleClose = () => {
    dispatch(resetSearchQueueData());
    setShowAddTable(false);
  };

  const populateSelectList = () => {
    const tempCountForEachType = [];
    const tempTaskTypes = [...taskTypesWithCounts];

    tempTaskTypes?.forEach((taskType) => {
      tempCountForEachType.push({
        ...taskType,
        totalCount: taskType.processedTotal,
      });
    });

    setTaskTypeSelectList(tempCountForEachType);
  };

  const handleSearchTerm = (e) => {
    if (validateSearchField) {
      setValidateSearchField(false);
    }
    setSearchTerm(e.target.value);
  };

  const handleTaskType = (e) => {
    taskTypeSelectList.forEach((item) => {
      if (item.task_type_id === e.target.value) {
        dispatch(setItemTaskName(item.task_type_name));
        dispatch(setItemTaskType(e.target.value));
      }
    });
  };

  const handleSearchOnClick = (e) => {
    e.preventDefault();
    if (searchTerm !== "") {
      setValidateSearchField(false);
      dispatch(setSearchQuery(searchTerm));
      dispatch(
        getTaskDataFromSearchTerm({
          searchTerm: searchTerm,
          typeId: itemTaskType,
          orderBy: orderBy,
          dataFrom: 1,
          rowAmount: rowsPerPage ? rowsPerPage : 10,
          sortDirection: sortDirection ? sortDirection : 0,
        })
      );
    } else {
      setValidateSearchField(true);
      dispatch(setSearchData([]));
    }
  };

  const handleSnackClose = () => {
    dispatch(resetAlert());
  };

  const handleToastClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (toastError) {
      dispatch(setToastError(false));
    } else if (toastWarning) {
      dispatch(setToastWarning(false));
    }
  };

  return (
    <PaperContainer>
      <Container
        style={{
          minWidth: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Search page
        </Typography>
        <Box
          marginTop="2em"
          style={{
            width: "100%",
            paddingTop: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "2em",
          }}
          paddingBottom="1em"
        >
          <Box display="flex">
            <TaskTypeSelectList
              taskTypesSelect={taskTypeSelectList}
              handleTaskType={handleTaskType}
              selectedTaskType={itemTaskType}
            />
            <TextField
              id="search-task-type"
              label={
                validateSearchField
                  ? "Please check the barcode and try again."
                  : "Enter query"
              }
              variant="outlined"
              onChange={(e) => handleSearchTerm(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchOnClick(e);
                }
              }}
              error={validateSearchField ? true : false}
            />
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleSearchOnClick(e)}
                style={{ height: "100%", width: "100%" }}
              >
                <SearchIcon fontSize="large" />
              </Button>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={showAdd}
            disabled={
              !searchTerm ||
              searchTerm.length === 0 ||
              !itemTaskType ||
              itemTaskType === 0
            }
          >
            <AddIcon />
          </Button>
        </Box>
        {!dataLoading && (
          <ReactTable
            columns={columnsForTable}
            data={searchData}
            selectedTaskType={itemTaskType}
            paginationTaskTotal={paginationTaskTotal}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setSortDirection={setSortDirection}
            setOrderBy={setOrderBy}
          />
        )}
        {dataLoading && <LoadingSpinner />}
        <Modal
          open={showAddTable}
          onClose={handleClose}
          aria-labelledby="edit task type"
          onBackdropClick={() => handleClose}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              p: 4,
              minWidth: "400px",
            }}
          >
            <AddToQueue
              taskType={itemTaskType}
              search={searchTerm}
              handleClose={handleClose}
              searchData={searchData}
            />
          </Box>
        </Modal>
        <Toast
          selectedToast={toastError}
          handleToastClose={(e) => handleToastClose(e)}
          alertType="error"
          message="Failed to load data."
        />
        <Toast
          selectedToast={toastWarning}
          handleToastClose={(e) => handleToastClose(e)}
          alertType="warning"
          message="No data found - try another task type or search query."
        />
        <Snackbar
          open={alertData.open}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Alert
            onClose={handleSnackClose}
            severity={alertData.severity}
            sx={{ width: "100%" }}
          >
            {typeof alertData.message === 'string' ? alertData.message :

              alertData.message.map((item) =>
                <div>{item}<br /></div>
              )
            }
          </Alert>
        </Snackbar>
      </Container>
    </PaperContainer>
  );
};

export default SearchPageTableView;
