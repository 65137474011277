import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { useState, useEffect, useRef } from "react";
import "./styles/App.css";
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import {
  AuthenticatedComponent,
  AuthProvider,
} from "@paversltd/react-stiletto-auth";
import {
  AppSidebar,
  LoginForm,
  StilettoToolbar,
  paversTheme,
} from "@paversltd/react-stiletto-components";
import BarChartIcon from "@material-ui/icons/BarChart";
import Settings from "@material-ui/icons/Settings";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionIcon from "@material-ui/icons/Description";
import { ThemeProvider } from "@material-ui/core/styles";
import { version } from "../package.json";
import useComponentSize from "@rehooks/component-size";
import Analytics from "./pages/Analytics";
import Config from "./pages/Config";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router";
import { Typography } from "@material-ui/core";
import styled from "@emotion/styled";
import { makeStyles } from "@material-ui/core/styles";
import { loadCSS } from "fg-loadcss";
import ProcessedTaskTableView from "./pages/ProcessedTaskTableView";
import ProcessingTaskTableView from "./pages/ProcessingTaskTableView";
import ErrorTaskTableView from "./pages/ErrorTaskTableView";
import SearchPageTableView from "./pages/SearchPageTableView";
import MonitoringView from "./pages/MonitoringView";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SearchBarcodeView } from "./pages/SearchBarcodeView";

const LoginWrapper = (props) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Typography variant="h3">{process.env.REACT_APP_TITLE}</Typography>
    <SubHeader component="h2" variant="h5" paragraph>
      Version {version}
    </SubHeader>
    <LoginForm {...props} />
  </Box>
);

const useStyles = makeStyles(() => ({
  icon: {
    width: 32,
  },
}));

const SubHeader = styled(Typography)`
  opacity: 0.66;
`;

const App = () => {
  const navigate = useNavigate();
  const [isSelectedAnaytics, setIsSelectedAnalytics] = useState(true);
  const [isSelectedSettings, setIsSelectedSettings] = useState(false);
  const [isSelectedSearch, setIsSelectedSearch] = useState(false);
  const [isSelectedMonitoring, setIsSelectedMonitoring] = useState(false);
  const [isSelectedBarcodeSearch, setIsSelectedBarcodeSearch] = useState(false);

  const sidebarItems = [
    [
      {
        id: "analytics",
        title: "Analytics",
        tooltip: "Analytics",
        handleClick: () => navigate("/"),
        icon: <BarChartIcon fontSize="large" />,
        isDisabled: false,
        isHidden: false,
        isSelected: isSelectedAnaytics,
      },
      {
        id: "Config",
        title: "Config",
        tooltip: "Config",
        handleClick: () => navigate("/config"),
        icon: <Settings fontSize="large" />,
        isSelected: isSelectedSettings,
      },
      {
        id: "Search",
        title: "Search",
        tooltip: "Search for item",
        handleClick: () => navigate("/search-page-view"),
        icon: <SearchIcon fontSize="large" />,
        isSelected: isSelectedSearch,
      },
      {
        id: "Logs",
        title: "Logs",
        tooltip: "View logs to see flow",
        handleClick: () => navigate("/logs-page-view"),
        icon: <DescriptionIcon fontSize="large" />,
        isSelected: isSelectedMonitoring,
      },
      {
        id: "SearchBarcode",
        title: "Check barcode",
        tooltip: "View ups or despatch barcodes/labels",
        handleClick: () => navigate("/search-ups-despatch-page-view"),
        icon: <CheckBoxIcon fontSize="large" />,
        isSelected: isSelectedBarcodeSearch,
      },
      // StorageIcon
    ],
    <div style={{ flexGrow: 1 }} />,
    <Divider />,
  ];

  const theme = useTheme();
  const [appbarRef, setAppbarRef] = useState(useRef(null));

  useEffect(() => {
    setAppbarRef(appbarRef);
  }, [appbarRef]);

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      loadCSS(
        "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
        document.querySelector("#font-awesome-css")
      );
    }
    return () => {
      didCancel = true;
    };
  }, []);

  const size = useComponentSize(appbarRef);
  const toolbarHeight = size.height;

  return (
    <>
      <Box
        style={{
          marginTop: toolbarHeight + theme.spacing(2),
        }}
      >
        <AuthProvider>
          <ThemeProvider theme={paversTheme}>
            <Box display="flex" flexDirection="column">
              <AuthenticatedComponent
                blockedComponent={<LoginWrapper />}
                render={(auth) => {
                  return (
                    <>
                      <StilettoToolbar
                        auth={auth}
                        getAppbarRef={(ref) => setAppbarRef(ref)}
                        title="Keymas Analytics"
                        username={
                          auth.user && auth.user.first_name
                            ? auth.user.first_name
                            : auth.username
                        }
                      />
                      <Box display="flex" height="90vh">
                        <AppSidebar
                          defaultOpen={false}
                          drawerCloseWidth={80}
                          drawerOpenWidth={260}
                          onClose={() => console.log("onClose")}
                          onOpen={() => console.log("onOpen")}
                          items={sidebarItems}
                          top={toolbarHeight}
                        />
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <Analytics
                                setIsSelectedAnalytics={setIsSelectedAnalytics}
                                setIsSelectedSettings={setIsSelectedSettings}
                                setIsSelectedSearch={setIsSelectedSearch}
                                setIsSelectedMonitoring={
                                  setIsSelectedMonitoring
                                }
                                setIsSelectedBarcodeSearch={
                                  setIsSelectedBarcodeSearch
                                }
                              />
                            }
                          />
                          <Route
                            path="/config"
                            element={
                              <Config
                                setIsSelectedAnalytics={setIsSelectedAnalytics}
                                setIsSelectedSettings={setIsSelectedSettings}
                                setIsSelectedSearch={setIsSelectedSearch}
                                setIsSelectedMonitoring={
                                  setIsSelectedMonitoring
                                }
                                setIsSelectedBarcodeSearch={
                                  setIsSelectedBarcodeSearch
                                }
                              />
                            }
                          />
                          <Route
                            path="/search-page-view"
                            element={
                              <SearchPageTableView
                                setIsSelectedAnalytics={setIsSelectedAnalytics}
                                setIsSelectedSettings={setIsSelectedSettings}
                                setIsSelectedSearch={setIsSelectedSearch}
                                setIsSelectedMonitoring={
                                  setIsSelectedMonitoring
                                }
                                setIsSelectedBarcodeSearch={
                                  setIsSelectedBarcodeSearch
                                }
                              />
                            }
                          />
                          <Route
                            path="/logs-page-view"
                            element={
                              <MonitoringView
                                setIsSelectedAnalytics={setIsSelectedAnalytics}
                                setIsSelectedSettings={setIsSelectedSettings}
                                setIsSelectedSearch={setIsSelectedSearch}
                                setIsSelectedMonitoring={
                                  setIsSelectedMonitoring
                                }
                                setIsSelectedBarcodeSearch={
                                  setIsSelectedBarcodeSearch
                                }
                              />
                            }
                          />
                          <Route
                            path="search-ups-despatch-page-view"
                            element={
                              <SearchBarcodeView
                                setIsSelectedAnalytics={setIsSelectedAnalytics}
                                setIsSelectedSettings={setIsSelectedSettings}
                                setIsSelectedSearch={setIsSelectedSearch}
                                setIsSelectedMonitoring={
                                  setIsSelectedMonitoring
                                }
                                setIsSelectedBarcodeSearch={
                                  setIsSelectedBarcodeSearch
                                }
                              />
                            }
                          />
                          <Route
                            path="/processed-tasks-table-view"
                            element={<ProcessedTaskTableView />}
                          />
                          <Route
                            path="/processing-tasks-table-view"
                            element={<ProcessingTaskTableView />}
                          />
                          <Route
                            path="/errored-tasks-table-view"
                            element={<ErrorTaskTableView />}
                          />
                        </Routes>
                      </Box>
                    </>
                  );
                }}
                requiredPermissions="STILETTO"
              />
            </Box>
          </ThemeProvider>
        </AuthProvider>
      </Box>
    </>
  );
};

export default App;
