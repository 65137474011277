import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Container,
  makeStyles,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ManageConfig from "../components/config/manageConfig";
import ManageSchedule from "../components/config/manageSchedule";
import SwitchBoardTaskTypes from "../components/config/switchTaskTypes";
import { resetAlert } from "../store/reducers/slices/configSlice";
import { useSelector, useDispatch } from "react-redux";
import PaperContainer from "../components/Containers/PaperContainer";

import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledPaper = withTheme(styled(Paper)`
  padding: ${(props) => props.theme.spacing(2)}px;
  width: ${(props) => (props.$fullWidth ? "100vw" : null)};
`);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Config = ({
  setIsSelectedAnalytics,
  setIsSelectedSettings,
  setIsSelectedSearch,
  setIsSelectedMonitoring,
  setIsSelectedBarcodeSearch,
}) => {
  const dispatch = useDispatch();
  const { alertData } = useSelector((state) => state.manageConfigData);
  console.log(alertData);

  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    dispatch(resetAlert());
  };
  useEffect(() => {
    setIsSelectedAnalytics(false);
    setIsSelectedSettings(true);
    setIsSelectedSearch(false);
    setIsSelectedMonitoring(false);
    setIsSelectedBarcodeSearch(false);
  }, []);

  return (
    <PaperContainer>
      <Box display="flex" justifyContent="center">
        <ManageConfig />
      </Box>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Task Types" {...a11yProps(0)} />
          <Tab label="Schedule" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <SwitchBoardTaskTypes />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box display="flex" justifyContent="center">
          <ManageSchedule />
        </Box>
      </TabPanel>

      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={alertData.severity}
          sx={{ width: "100%" }}
        >
          {alertData.message}
        </Alert>
      </Snackbar>
    </PaperContainer>
  );
};

export default Config;
