import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useEffect } from 'react';
import ReactTable from './ReactTable';
import DefaultColumnFilter from '../ReactTable/DefaultColumnFilter';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQueuedTaskWithErrorDetails } from '../../../store/reducers/slices/errorTableSlice';
import '../../../styles/errortable.css';

const ViewErrorDialog = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const { errorQueueData, errorTaskQueueId } = useSelector(
    (state) => state.manageErrorTable
  );

  useEffect(() => {
    console.log('errorTaskQueueId : ', errorTaskQueueId);
    dispatch(
      fetchQueuedTaskWithErrorDetails({ task_queue_id: errorTaskQueueId })
    );
  }, [errorTaskQueueId]);

  useEffect(() => {
    console.log('errorQueueData : ', errorQueueData);
  }, [errorQueueData]);

  const columnsForTable = [
    {
      Header: 'Task queue id',
      id: 'task_queue_id',
      accessor: (t) => t.task_queue_id,
      Filter: DefaultColumnFilter,
      Cell: (params) => params.cell.value,
    },
    {
      Header: 'Error id',
      id: 'error_id',
      accessor: (t) => t.error_id,
      Filter: DefaultColumnFilter,
      Cell: (params) => params.cell.value,
    },
    {
      Header: 'Error message',
      id: 'error_message',
      accessor: (t) => t.error_message,
      Filter: DefaultColumnFilter,
      Cell: ({ cell: { value } }) => {
        try {
          const startIdx = value.indexOf('{');
          if (startIdx === -1) throw new Error('Invalid JSON');

          const jsonStr = value.substring(startIdx);
          const errorData = JSON.parse(jsonStr);
          const items = errorData.items || [];

          return (
            <div className="scrollable-cell">
              {items.map((item, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                  <strong>Task PAVFK ID:</strong> {item.task_pavfk_id}
                  <br />
                  <strong>Task Type ID:</strong> {item.task_type_id}
                  <br />
                  <strong>Name:</strong> {item.name}
                  <br />
                  <strong>Description:</strong> {item.description}
                  <br />
                  <strong>Long Description:</strong> {item.long_description}
                  <br />
                  <strong>Item Variants:</strong>
                  <ul>
                    {item.item_variants.map((variant, vIndex) => (
                      <li key={vIndex}>
                        <strong>Item SKU:</strong> {variant.item_sku}
                        <br />
                        <strong>Pack Quantity:</strong> {variant.pack_quantity}
                        <br />
                        <strong>Comment:</strong> {variant.comment}
                        <br />
                        <strong>Weight:</strong> {variant.weight}
                        <br />
                        <strong>Height:</strong> {variant.height}
                        <br />
                        <strong>Width:</strong> {variant.width}
                        <br />
                        <strong>Depth:</strong> {variant.depth}
                        <br />
                        <strong>Small Item:</strong> {variant.small_item}
                        <br />
                        <strong>Refill Threshold Qty:</strong>{' '}
                        {variant.refill_threshold_qty}
                        <br />
                        <strong>Replen 1:</strong> {variant.replen_1}
                        <br />
                        <strong>Stock Value:</strong> {variant.stock_value}
                        <br />
                        <strong>EAN Code:</strong> {variant.eanCode}
                        <br />
                        <strong>POS Code:</strong> {variant.posCode}
                        <br />
                        <strong>Location:</strong> {variant.location}
                        <br />
                        <strong>Barcodes:</strong> {variant.barcodes.join(', ')}
                        <br />
                        <strong>Attributes:</strong>
                        <ul>
                          {variant.attributes.map((attr, aIndex) => (
                            <li key={aIndex}>
                              <strong>Style:</strong> {attr.style}
                              <br />
                              <strong>Colour:</strong> {attr.colour}
                              <br />
                              <strong>Size:</strong> {attr.size}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          );
        } catch (e) {
          return <div className="scrollable-cell">{value}</div>;
        }
      },
    },
  ];

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xl">
      <DialogTitle>Task queue error logs</DialogTitle>
      <DialogContent>
        {errorQueueData.length > 0 && (
          <ReactTable columns={columnsForTable} data={errorQueueData} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewErrorDialog;
