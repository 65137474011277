import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  SEARCH_TASK_TYPES,
  GET_ALL_TASK_DATA,
  GET_COUNT_TASKS,
  GET_TYPE_DATA_FROM_SEARCH_TERM,
} from "../../types";
import axios from "axios";
import moment from "moment";
import format from "date-fns/format";

const DATE_FORMAT = 'yyyy-MM-dd';

const { REACT_APP_API_BASE_URI } = process.env;
const params = {
  headers: {
    deviceToken: "0e07b48d-b30f-4e02-b554-f23aad4c4b35",
    "Access-Control-Allow-Origin": "*",
    "x-jwt-token":
      "eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds",
  },
};

export const processing = "processing...";

export const getTaskData = createAsyncThunk(
  GET_ALL_TASK_DATA,
  async (data, { rejectWithValue }) => {
    const { startDate, endDate } = data;
    let tempStartDate = format(startDate, DATE_FORMAT);
    let tempEndDate = format(endDate, DATE_FORMAT);
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getTaskData?${tempStartDate && tempEndDate
          ? `start_date=${tempStartDate}&end_date=${tempEndDate}`
          : ""
        }`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("unable to get data.");
      }

      return response.data.data[0];
    } catch (e) {
      console.error(e);
    }
  }
);

export const getAllTaskTypeCounts = createAsyncThunk(
  GET_COUNT_TASKS,
  async (data, { rejectWithValue }) => {
    const { startDate, endDate } = data;
    let tempStartDate = format(startDate, DATE_FORMAT);
    let tempEndDate = format(endDate, DATE_FORMAT);

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getAllTaskTypeCounts?start_date=${tempStartDate}&end_date=${tempEndDate}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("unable to get data.");
      }

      return response.data.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getTaskDataFromSearchTerm = createAsyncThunk(
  GET_TYPE_DATA_FROM_SEARCH_TERM,
  async (data, { rejectWithValue }) => {
    const { searchTerm, typeId } = data;

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getTaskDataFromSearchTerm?searchTerm=${searchTerm}&typeId=${typeId}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("unable to get data");
      }

      return response.data.data;
    } catch (err) {
      console.error(err);
    }
  }
);

const searchSlice = createSlice({
  name: SEARCH_TASK_TYPES,
  initialState: {
    loading: false,
    dataLoading: false,
    toastError: false,
    toastWarning: false,
    itemTaskType: 1, // overall total by default (0 = overall total / 1 = supplier / 2 = product etc...)
    itemTaskName: "Overall Total",
    searchQuery: "",
    taskTypesWithCounts: [],
    orderBy: " ",
    paginationTaskTotal: 0,
    page: 0,
    rowsPerPage: 10,
    sortDirection: 1, // asc/desc
    requestSort: false,
    completedSort: false,
    queueSort: false,
    searchData: [],
  },
  reducers: {
    setTaskTypesWithCounts: (state, action) => {
      state.taskTypesWithCounts = action.payload;
    },

    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },

    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },

    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },

    setItemTaskType: (state, action) => {
      state.itemTaskType = action.payload;
    },

    setItemTaskName: (state, action) => {
      state.itemTaskName = action.payload;
    },

    setRequestSort: (state, action) => {
      state.requestSort = action.payload;
    },

    setCompletedSort: (state, action) => {
      state.completedSort = action.payload;
    },

    setQueueSort: (state, action) => {
      state.queueSort = action.payload;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },

    setSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },

    setToastError: (state, action) => {
      state.toastError = action.payload;
    },

    setToastWarning: (state, action) => {
      state.toastWarning = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTaskDataFromSearchTerm.fulfilled, (state, action) => {
      state.dataLoading = false;
      console.log("fulfilled");
      if (action?.payload) {
        if (action.payload[1] && action.payload[1]?.length > 0) {
          const tempData = [];
          action.payload[1]?.forEach((i) => {
            tempData.push({
              ...i,
              task_date_requested: moment(i.task_date_requested).format(
                "DD-MM-YYYY, hh:mm:ss"
              ),
              task_date_completed: i.task_date_completed
                ? moment(i.task_date_completed).format("DD-MM-YYYY, hh:mm:ss")
                : processing,
            });
          });
          state.searchData = tempData;
          state.paginationTaskTotal = action.payload[0][0]?.total
            ? action.payload[0][0].total
            : 0;
          state.toastWarning = false;
          state.toastError = false;
        } else {
          state.toastWarning = true;
        }
      } else {
        state.searchData = [];
        state.rowsPerPage = 10;
        state.page = 0;
        state.paginationTaskTotal = 0;
        state.toastWarning = true;
      }
    });

    builder.addCase(getTaskDataFromSearchTerm.pending, (state) => {
      state.searchData = [];
      state.dataLoading = true;
      console.log("pending");
    });

    builder.addCase(getTaskDataFromSearchTerm.rejected, (state) => {
      console.log("failed");
      state.dataLoading = false;
      state.toastError = true;
      state.toastWarning = false;
    });

    builder.addCase(getTaskData.fulfilled, (state, action) => {
      state.loading = false;
      state.taskTypesWithCounts = action.payload;
    });

    builder.addCase(getTaskData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTaskData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setItemTaskType,
  setItemTaskName,
  setTaskTypesWithCounts,
  setToastError,
  setToastWarning,
  setPaginationTaskTotal,
  setPage,
  setRowsPerPage,
  setSortDirection,
  setRequestSort,
  setQueueSort,
  setCompletedSort,
  setSearchQuery,
  setSearchData,
  setOrderBy,
} = searchSlice.actions;
export default searchSlice.reducer;
