import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Snackbar,
  makeStyles,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAlert,
  resetAlert,
  checkIfLabelForRegenerationIsPrepack,
  regenerateLabel
} from "../../store/reducers/slices/monitoringSlice";
import { useState } from "react";
import { Alert } from "@material-ui/lab";
import { SearchedIdModal } from "../monitoring/modals/searchedIdModal";
import MonitoringContainer from "../Containers/MonitoringContainer";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import { monitoringStyling } from "../../styles/MonitoringStyling";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({}));

export const BarcodeGridItem = ({
  header,
  barcodeValidation,
  setBarcode,
  handleBarcodeSearch,
  loading,
  searched,
  searchLoading,
  setSearched,
  hasLabelGenerated,
  searchForItemById,
  noteSearchLoading,
  resetLabelCheckAfterSearch,
  despatchSpecific,
  setIsDespatchId,
  isDespatchId,
  barcode
}) => {
  const classes = monitoringStyling();
  const dispatch = useDispatch();
  const despIdLabel = "Despatch id?";

  const { alertData } = useSelector((state) => state.manageMonitoringData);

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const handleOpen = (log) => {
    setModalData(log);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setModalData({});
  };

  const handleAlertClose = () => {
    dispatch(resetAlert());
  };

  const handleIdAlertOnClick = () => {
    if (searchForItemById._id) {
      handleOpen(searchForItemById);
    } else {
      dispatch(
        addAlert({
          open: true,
          severity: "error",
          message: "No id found. Try again or try another id.",
        })
      );
    }
  };


  const handleRegenerateLabelClick = () => {
    console.log(`${barcode}`)
    dispatch(
      checkIfLabelForRegenerationIsPrepack({ searchTerm: `${barcode}` })
    ).then((res) => {
      const labelDataObj = res.payload.data[0];
      if (labelDataObj !== undefined) {
        try {
          dispatch(
            regenerateLabel(labelDataObj)).then((res) => {
              if (res.payload === 200) {
                dispatch(
                  addAlert({
                    open: true,
                    severity: "success",
                    message: "Successfully sent label for regeneration",
                  })
                );
              }
            })
        } catch (e) {
          console.log('Invalid Carton Barcode')
          dispatch(
            addAlert({
              open: true,
              severity: "error",
              message: "Invalid Carton Barcode",
            })
          );
        }
      }
      else {
        dispatch(
          addAlert({
            open: true,
            severity: "error",
            message: "There was a problem regenerating the label. Try again later",
          })
        );
      }
    }).catch((e) => {
      dispatch(
        addAlert({
          open: true,
          severity: "error",
          message: "There was a problem checking the label, try again later.",
        })
      );
    });
  }

  useEffect(() => {
    if (searched || searchLoading) {
      const barcodeStatusInterval = setInterval(() => {
        if (searched) {
          setSearched(false);
        }
      }, 5000);

      return () => {
        clearInterval(barcodeStatusInterval);
        if (hasLabelGenerated) {
          dispatch(resetLabelCheckAfterSearch());
        }
      };
    }
  }, [
    searched,
    dispatch,
    hasLabelGenerated,
    resetLabelCheckAfterSearch,
    searchLoading,
    setSearched,
  ]);

  useEffect(() => {
    if (!loading && searched && hasLabelGenerated) {
      dispatch(
        addAlert({
          open: true,
          severity: "success",
          message: "Item processed. Currently stored within the database.",
        })
      );
    }

    if (!loading && searched && !searchForItemById.id && !hasLabelGenerated) {
      dispatch(
        addAlert({
          open: true,
          severity: "error",
          message: "No id found. Try again or try another id.",
        })
      );
    }
  }, [dispatch, searched, loading, searchForItemById, hasLabelGenerated]);

  return (
    <>
      <Grid container item xs={6} className={classes.barcodeGridItemHeight}>
        <MonitoringContainer>
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                variant="h4"
                className={classes.barcodeGridItemHeader}
              >
                {header}
              </Typography>
              <FormGroup
                className={classes.barcodeItemsWrapper}
                style={!despatchSpecific ? { justifyContent: "center" } : {}}
              >
                <Box
                  marginY="2em"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Accordion variant="outlined">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ justifyContent: "flex-start", paddingLeft: "1em" }}>
                      <Typography variant="h6" style={{ textAlign: "center" }}>
                        Working's of the search bar
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h5">Instructions</Typography>
                        <Typography
                          variant="body1"
                          style={{ marginTop: "1em" }}
                        >
                          Enter a{" "}
                          {despatchSpecific
                            ? "despatch note or a despatch Id"
                            : "despatch label"}{" "}
                          to check if the item has been processed.
                        </Typography>
                        <Typography style={{ marginTop: "1em" }}>
                          if a tick icon appears below, then the item has
                          successfully been processed.
                        </Typography>
                        <Typography style={{ marginTop: "1em" }}>
                          If the icon appears as a red warning, the item either
                          hasn't been fully processed or does not exist.
                        </Typography>
                        <Typography style={{ marginTop: "1em" }}>
                          Additionally if the item exists and is processing, the
                          red warning icon can be clicked on to view the current
                          status of that item (within a 7 day time period).
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="row"
                  >
                    <TextField
                      variant="outlined"
                      label={
                        barcodeValidation ? "Enter valid code" : "Check code"
                      }
                      onChange={(e) => setBarcode(e.target.value)}
                      error={barcodeValidation ? true : false}
                      inputProps={{
                        style: { fontSize: "25px" },
                      }}
                    />
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleBarcodeSearch(e)}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <SearchIcon fontSize={"large"} />
                      </Button>
                    </Box>
                  </Box>
                  {despatchSpecific && (
                    <Box display="flex" width="100%" height="45px">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() => setIsDespatchId(!isDespatchId)}
                          />
                        }
                        label={
                          <Typography
                            variant="body1"
                            style={{ marginBottom: "0.1em" }}
                          >{`${despIdLabel}`}</Typography>
                        }
                        labelPlacement="end"
                      />
                    </Box>
                  )}
                </Box>
                {!despatchSpecific && ( // to fill in gap so both searches are in-line
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleRegenerateLabelClick()}
                      sx={{ marginTop: 10 }}
                    >Regenerate Label</Button>
                  </Box>)}

              </FormGroup>
            </Box>
            <Box display="flex" justifyContent="center">
              {!loading && searched && (
                <>
                  {hasLabelGenerated && (
                    <DoneIcon fontSize="Large" className={classes.doneIcon} />
                  )}
                  {hasLabelGenerated === false && (
                    <Button onClick={() => handleIdAlertOnClick()}>
                      <ErrorIcon
                        fontSize="Large"
                        className={classes.errorIcon}
                      />
                    </Button>
                  )}
                </>
              )}
              {noteSearchLoading && (
                <Box display="flex">
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </>
        </MonitoringContainer>
      </Grid>
      <SearchedIdModal
        data={modalData}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertData.severity}
          sx={{ width: "100%" }}
        >
          {alertData.message}
        </Alert>
      </Snackbar>
    </>
  );
};
