import React, { useEffect, useState } from 'react';
import ReactTable from '../components/tables/ReactTable/ReactTable';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import PaperContainer from '../components/Containers/PaperContainer';
import {
  getTaskData,
  getAllTaskTypeCounts,
} from '../store/reducers/slices/dashboardDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  fetchErroredDataInFull,
  setErrorTaskQueueId,
  updateErroredTaskToQueue,
  setToastError,
  setToastWarning,
  setToastSuccess,
} from '../store/reducers/slices/errorTableSlice';
import DefaultColumnFilter from '../components/tables/ReactTable/DefaultColumnFilter';
import SelectColumnFilterWithTotals from '../components/tables/ReactTable/SelectColumnFilterWithTotals';
import SelectColumnFilterWithoutTimev2 from '../components/tables/ReactTable/SelectColumnFilterWithoutTimev2';
import ViewErrorDialog from '../components/tables/ReactTable/viewErrorDialog';
import Toast from '../components/toast/Toast';

const ErrorTaskTableView = () => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const { startDate, endDate } = useSelector((state) => state.manageGraphData);
  const { selectedTaskType } = useSelector(
    (state) => state.manageDashboardData
  );

  const {
    data,
    paginationTaskTotal,
    loadingTableData,
    toastError,
    toastWarning,
    toastSuccess,
  } = useSelector((state) => state.manageErrorTable);

  const [errorDialog, setErrorDialog] = useState(false);

  const closeErrorDialog = () => setErrorDialog(false);

  const handleRowSelection = (id) => {
    setSelectedRows((prevState) => {
      if (prevState.includes(id)) {
        // Unselect if already selected
        return prevState.filter((rowId) => rowId !== id);
      } else {
        // Add to selected rows
        return [...prevState, id];
      }
    });
  };

  const resendThenGet = async (ids) => {
    const taskQueueIds = Array.isArray(ids) ? ids : [ids];
    setTimeout(() => {
      dispatch(updateErroredTaskToQueue({ task_queue_ids: taskQueueIds }));
      dispatch(getTaskData({}));
      dispatch(
        getAllTaskTypeCounts({ startDate: new Date(), endDate: new Date() })
      );
    }, 1000);

    setTimeout(() => {
      dispatch(
        fetchErroredDataInFull({
          type_id: selectedTaskType,
          start_date: startDate,
          end_date: endDate,
        })
      );
    }, 1200);
  };

  const columnsForTable = [
    {
      Header: 'Task queue id',
      id: 'task_queue_id',
      accessor: (t) => t.task_queue_id,
      Filter: DefaultColumnFilter,
      Cell: (params) => (
        <Button
          onClick={async () => {
            await dispatch(
              setErrorTaskQueueId(params.row.original.task_queue_id)
            );
            setErrorDialog(true);
          }}
        >
          {params.cell.value}
        </Button>
      ),
    },
    {
      Header: 'Type name',
      id: 'task_type_name',
      accessor: (t) => {
        return `${t.task_type_name}`;
      },
      Filter: SelectColumnFilterWithTotals,
      filter: 'exactTextCase', // adds exact text filtering - https://github.com/TanStack/react-table/discussions/2859
    },
    {
      accessor: (originalRow) =>
        moment(
          originalRow['task_date_requested'],
          'YYYY-MM-DD HH:mm:ss'
        ).toDate(),
      id: 'task_date_requested',
      Header: 'Date requested',
      Filter: SelectColumnFilterWithoutTimev2,
      Cell: (params) => {
        return (
          <>
            {moment(
              params.row.values.task_date_requested,
              'DD-MM-YYYY HH:mm:ss'
            )
              .format('DD/MM/YYYY, HH:mm:ss')
              .toString()}
          </>
        );
      },
      sortType: 'datetime',
    },
    {
      Header: 'Reference',
      id: 'reference',
      accessor: (t) => (t.reference ? t.reference : 'None'),
      Filter: DefaultColumnFilter,
    },
    {
      Header: 'Manage queued task',
      id: 'select',
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ marginLeft: 80, width: 15, height: 15 }}
          checked={selectedRows.some(
            (rowId) => rowId === row.original.task_queue_id
          )}
          onChange={() => handleRowSelection(row.original.task_queue_id)}
        />
      ),
      width: 100,
    },
  ];

  const handleToastClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setTimeout(() => {
      if (toastError) {
        dispatch(setToastError(false));
      } else if (toastWarning) {
        dispatch(setToastWarning(false));
      } else {
        dispatch(setToastSuccess(false));
      }
    }, 1500);
  };

  useEffect(() => {
    dispatch(getTaskData({}));
    dispatch(
      getAllTaskTypeCounts({ startDate: new Date(), endDate: new Date() })
    );
    dispatch(
      fetchErroredDataInFull({
        type_id: selectedTaskType,
        start_date: startDate,
        end_date: endDate,
      })
    );
  }, [dispatch, selectedTaskType, startDate, endDate]);

  useEffect(() => {
    // Clear selected rows when data changes or loadingTableData changes
    if (!loadingTableData) {
      setSelectedRows([]);
    }
  }, [data, loadingTableData]);

  return (
    <>
      <PaperContainer>
        <Box style={{ marginBottom: '1em' }}>
          <Typography variant="h2" style={{ textAlign: 'center' }}>
            Error table
          </Typography>
        </Box>
        {loadingTableData ? (
          <Box
            width="100%"
            height="90vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="30"
          >
            <CircularProgress size="5rem" />
          </Box>
        ) : (
          <>
            <Button
              onClick={() => resendThenGet(selectedRows)}
              disabled={selectedRows.length === 0}
              style={{
                float: 'right',
                background: selectedRows.length > 0 ? '#005170' : '#cccccc',
                color: selectedRows.length > 0 ? '#ffffff' : '#888888',
                padding: '5px 10px',
                borderRadius: 4,
                marginBottom: 20,
                cursor: 'pointer',
                fontSize: 12,
              }}
            >
              Resend Selected Tasks
              {selectedRows.length > 0 ? (
                <span
                  style={{ fontWeight: 'bold', marginLeft: 10, fontSize: 15 }}
                >
                  &#10003;
                </span>
              ) : (
                ''
              )}
            </Button>
            <ReactTable
              columns={columnsForTable}
              data={data}
              selectedTaskType={selectedTaskType}
              paginationTaskTotal={paginationTaskTotal}
            />
          </>
        )}
      </PaperContainer>
      <ViewErrorDialog handleClose={closeErrorDialog} open={errorDialog} />
      <Toast
        selectedToast={toastSuccess}
        handleToastClose={(e) => handleToastClose(e)}
        alertType="success"
        message="Task successfully added back to task queue."
      />

      <Toast
        selectedToast={toastError}
        handleToastClose={(e) => handleToastClose(e)}
        alertType="error"
        message="Unable to add task back to the queue."
      />
    </>
  );
};

export default ErrorTaskTableView;
