import React, { useRef, useEffect } from "react";
import {
    Box,
    Typography,
    Container,
    Grid,
    Switch,
    FormControlLabel,
    } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigData } from "../../store/reducers/slices/configSlice";
import { postConfigManage } from "../../store/reducers/slices/configSlice";
import Header from "../header/Header";


    export default function ManageConfig(){
    const {lastCheckForUpdates, useVanRoutes} = useSelector((state) => state.manageConfigData);
    const dispatch = useDispatch();
    let intervalId = useRef(null)

    const getConfigData = async () =>{
        dispatch(fetchConfigData())
      };

    useEffect(() => {
      getConfigData()
      const id = setInterval(() =>{
        getConfigData()
      }, 5000);
      intervalId.current = id;
      return () =>{
        clearInterval(intervalId.current)
      }
  }, []);

  useEffect(()=>{
    if(lastCheckForUpdates && lastCheckForUpdates !== ""){
      clearInterval(intervalId.current);
    }
  }, [lastCheckForUpdates])

    const handleVanRoutes = (event) => {
      handleUpdate(event.target.checked)
    };

    const handleUpdate = async(checked) =>{
      dispatch(
        postConfigManage({useVanRoutes: checked})
      ).then(()=>{
        getConfigData()
      })
    }

  return (
      <Container fixed>        
        <Grid
          item
          xs={12}
          md={12}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {!lastCheckForUpdates || lastCheckForUpdates === "" ?
            <Box>
              <Typography variant="h5">Could not connect to Database</Typography>
            </Box>:
            <div>
            {/* <Box>
              <Typography variant="h5">Config Manager:</Typography>
            </Box>
            <Typography variant="h6" style={{ textAlign: "center" }}>
              Last Updated : {lastCheckForUpdates ? `${lastCheckForUpdates.toLocaleTimeString()}` : ""}               {lastCheckForUpdates ? `${lastCheckForUpdates.toLocaleDateString("en-UK")}` : ""}
            </Typography> */}
            <Header pageName="Config Manager"/>
            <Box display="flex" justifyContent="center" marginTop="1.5em">
            <FormControlLabel control={<Switch
              checked={useVanRoutes}
              onChange={handleVanRoutes}
              color="primary"
              name="vanRoutes"
              inputProps={{ 'aria-label': 'Use Van Routes?' }}
            />}
          label="Use Van Routes" /> 
          </Box>
          </div>
            }
            
          </Box>
        </Grid>
      </Container>
  );
};