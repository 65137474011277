import { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types'

import {
    Table as MUITable,
    TablePagination,
    TableFooter,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableSortLabel,
} from '@material-ui/core'
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'

import TablePaginationActions from './ReactTable/TablePaginationActions'

export const defaultInitialState = {
    filters: [],
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
}

export default function ButtonReactTable({
    data,
    columns,
    id,
    checkBoxTable,
    setCheckedRows,
    initialState,
    className,
    tableName,
    persistState
}) {
    const isControlled = persistState && tableName
    const persistedState = isControlled
        ? JSON.parse(sessionStorage.getItem(`epm.${tableName}`))
        : {}

    const mergedInitialState = Object.assign(
        {},
        defaultInitialState,
        initialState,
        persistedState,
    )

    const {
        gotoPage,
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        setPageSize,
        state: { pageIndex, pageSize, filters, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: mergedInitialState,
        },
        useFilters,
        useSortBy,
        usePagination,
    )

    useEffect(() => {
        if (!isControlled) {
            return (
                sessionStorage.setItem(`epm.${tableName}`, JSON.stringify({
                    pageIndex,
                    pageSize,
                    filters,
                    sortBy,
                }))
            )
        }
    }, [filters, isControlled, pageIndex, pageSize, sortBy, tableName])

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        const value = Number(event.target.value)
        setPageSize(value)
    }

    let startIndex = pageIndex * pageSize
    let endIndex = (pageIndex + 1) * pageSize
    // handle filter used on page > 1
    if (startIndex > rows.length) startIndex = 0

    const visibleRows = isControlled
        ? rows.slice(startIndex, endIndex)
        : page

    return (
        <MUITable {...getTableProps()} size="small" className={className} data-testid={id}>
            <TableHead>
                {headerGroups.map(headerGroup => {
                    const { key, ...rest } = headerGroup.getHeaderGroupProps()
                    return (
                        <Fragment key={key}>
                            <TableRow {...rest} data-testid="table-head">
                                {headerGroup.headers.map(column => (
                                    <TableCell
                                        {...(column.id === 'selection'
                                            ? column.getHeaderProps()
                                            : column.getHeaderProps(column.getSortByToggleProps()))}
                                        align={column.align}
                                        style={{ width: column.width }}
                                    >
                                        {column.id !== 'selection' ? (
                                            <TableSortLabel
                                                active={column.isSorted}
                                                // react-table has a unsorted state which is not treated here
                                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                            />
                                        ) : null}
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow
                                {...rest}
                                data-testid="table-filters"
                                style={{ backgroundColor: 'rgb(250, 250, 250)' }}
                            >
                                {headerGroup.headers.map(column => {
                                    return (
                                        <TableCell
                                            {...column.getHeaderProps()}
                                            align={column.align}
                                        >
                                            {column.canFilter ? column.render('Filter') : null}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </Fragment>
                    )
                })}
            </TableHead>

            <TableBody>
                {visibleRows.map((row, i) => {
                    prepareRow(row)
                    const { key, role } = row.getRowProps()
                    return (
                        <TableRow key={key} role={role} data-testid="table-row">
                            {row.cells.map(cell => {
                                return (
                                    <TableCell {...cell.getCellProps()} align={cell.column.align}>
                                        {cell.render('Cell')}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>

            <TableFooter data-testid="table-footer">
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[
                            5,
                            10,
                            25,
                        ]}
                        colSpan={columns.length}
                        count={rows.length}
                        rowsPerPage={pageSize}
                        page={pageIndex * pageSize > rows.length ? 0 : pageIndex}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            // native: true,
                        }}
                        // onChangePage={handleChangePage}
                        onPageChange={handleChangePage}
                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </MUITable>
    );
}

ButtonReactTable.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialState: PropTypes.object,
    persistState: PropTypes.bool,
    tableName: PropTypes.string,
}
ButtonReactTable.defaultProps = {
    className: null,
    initialState: {},
    persistState: false,
    tableName: null,
}