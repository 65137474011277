import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  CONF_SET_ALERT,
  GET_CONF_TASK_TYPES,
  ADD_CONF_TASK_TYPE,
  EDIT_CONF_TASK_TYPE,
  DELETE_CONF_TASK_TYPE,
  ADD_CONF_CRON,
  EDIT_CONF_CRON,
  DELETE_CONF_CRON,
  POST_CONF_MANAGE,
  GET_CONF_DATA,
  GET_CONF_SCHEDULE,
} from "../../types";
import axios from "axios";

const { REACT_APP_API_BASE_URI } = process.env;
const params = {
  headers: {
    deviceToken: "0e07b48d-b30f-4e02-b554-f23aad4c4b35",
    "Access-Control-Allow-Origin": "*",
    "x-jwt-token":
      "eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds",
  },
};

export const fetchConfigTaskTypes = createAsyncThunk(
  GET_CONF_TASK_TYPES,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getTaskTypes`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return response.data.types;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const fetchConfigData = createAsyncThunk(
  GET_CONF_DATA,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getLastUpdated`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return response.data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const fetchConfigSchedule = createAsyncThunk(
  GET_CONF_SCHEDULE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getScheduleData`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return response.data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const postConfigTaskAdd = createAsyncThunk(
  ADD_CONF_TASK_TYPE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/createNewTaskType`,
        data,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const postConfigTaskEdit = createAsyncThunk(
  EDIT_CONF_TASK_TYPE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/updateTaskType`,
        data,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const deleteConfigTaskType = createAsyncThunk(
  DELETE_CONF_TASK_TYPE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/deleteTaskType?id=` +
          data.taskTypeId,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const postConfigCronAdd = createAsyncThunk(
  ADD_CONF_CRON,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/createCronJob`,
        data,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const postConfigCronEdit = createAsyncThunk(
  EDIT_CONF_CRON,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/updateCronJob`,
        data,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const deleteConfigCron = createAsyncThunk(
  DELETE_CONF_CRON,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/deleteCronJob?id=` + data.id,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const postConfigManage = createAsyncThunk(
  POST_CONF_MANAGE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/postManageConfig`,
        data,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return data;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

const configSlice = createSlice({
  name: CONF_SET_ALERT,
  initialState: {
    alertData: { open: false, severity: "success", message: "" },
    typeData: [],
    lastCheckForUpdates: "",
    useVanRoutes: false,
    scheduleData: [],
  },
  reducers: {
    addAlert(state, action) {
      state.alertData = action.payload;
    },
    resetAlert(state) {
      state.alertData = { open: false, severity: "success", message: "" };
    },
    default(state) {
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchConfigData.fulfilled, (state, action) => {
      console.log("fulfilled task types");
      console.log(action.payload);
      state.lastCheckForUpdates = new Date(
        action.payload.lastUpdated[0].lastCheckForUpdates
      );
      state.useVanRoutes = action.payload.lastUpdated[0].useVanRoutes;
    });

    builder.addCase(fetchConfigData.rejected, (state) => {
      console.log("rejected task types");
    });

    builder.addCase(fetchConfigSchedule.fulfilled, (state, action) => {
      console.log("fulfilled config schedule");
      console.log(action.payload);
      state.scheduleData = action.payload.scheduleData;
    });

    builder.addCase(fetchConfigSchedule.rejected, (state) => {
      console.log("rejected schedule fetch");
    });

    builder.addCase(fetchConfigTaskTypes.fulfilled, (state, action) => {
      console.log("fulfilled task types");
      state.typeData = action.payload;
    });

    builder.addCase(fetchConfigTaskTypes.rejected, (state) => {
      console.log("rejected task types");
    });

    builder.addCase(postConfigTaskAdd.fulfilled, (state, action) => {
      console.log("created task type");
      state.alertData = {
        open: true,
        severity: "success",
        message: action.payload.task_type_name + " has been created",
      };
    });

    builder.addCase(postConfigTaskAdd.rejected, (state) => {
      console.log("failed to create task type");
      state.alertData = {
        open: true,
        severity: "error",
        message: "Could not create task",
      };
    });

    builder.addCase(postConfigTaskEdit.fulfilled, (state, action) => {
      console.log("edited task type");
      state.alertData = {
        open: true,
        severity: "success",
        message: "Updated task",
      };
    });

    builder.addCase(postConfigTaskEdit.rejected, (state) => {
      console.log("failed to edit task type");
      state.alertData = {
        open: true,
        severity: "error",
        message: "Could not update",
      };
    });

    builder.addCase(deleteConfigTaskType.fulfilled, (state, action) => {
      console.log("deleted task type");
      state.alertData = {
        open: true,
        severity: "success",
        message: action.payload.nameSet + " has been deleted",
      };
    });

    builder.addCase(deleteConfigTaskType.rejected, (state) => {
      console.log("failed to delete task type");
      state.alertData = {
        open: true,
        severity: "error",
        message: "Could not delete type",
      };
    });

    builder.addCase(postConfigCronAdd.fulfilled, (state, action) => {
      console.log("created cron");
      state.alertData = {
        open: true,
        severity: "success",
        message: "cron has been created",
      };
    });

    builder.addCase(postConfigCronAdd.rejected, (state) => {
      console.log("failed to create cron");
      state.alertData = {
        open: true,
        severity: "error",
        message: "Could not create cron",
      };
    });

    builder.addCase(postConfigCronEdit.fulfilled, (state, action) => {
      console.log("edited cron");
      state.alertData = {
        open: true,
        severity: "success",
        message: "Updated cron",
      };
    });

    builder.addCase(postConfigCronEdit.rejected, (state) => {
      console.log("failed to edit cron");
      state.alertData = {
        open: true,
        severity: "error",
        message: "Could not update cron",
      };
    });

    builder.addCase(deleteConfigCron.fulfilled, (state, action) => {
      console.log("deleted cron");
      state.alertData = {
        open: true,
        severity: "success",
        message: "cron job has been deleted",
      };
    });

    builder.addCase(deleteConfigCron.rejected, (state) => {
      console.log("failed to delete cron");
      state.alertData = {
        open: true,
        severity: "error",
        message: "Could not delete cron",
      };
    });

    builder.addCase(postConfigManage.fulfilled, (state, action) => {
      console.log("updated van routes");
      state.alertData = {
        open: true,
        severity: "success",
        message: "updated van routes",
      };
    });

    builder.addCase(postConfigManage.rejected, (state) => {
      console.log("failed to update van routes");
      state.alertData = {
        open: true,
        severity: "error",
        message: "Could not update van routes",
      };
    });
  },
});

export const { addAlert, resetAlert } = configSlice.actions;
export default configSlice.reducer;
